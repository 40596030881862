<template>
    <v-card
        width="900"        
        class="mx-auto pa-4"
        elevation="2"
        outlined
    >
        <v-card-title class="pb-0">
            Subject Student Assignment
        </v-card-title>

        <v-container>
            <v-row>
                <v-col cols="4">
                    <v-autocomplete
                        v-model="selectedSubject"
                        outlined
                        dense
                        label="Subject"
                        placeholder="Select Subject"
                        chips
                        small-chips
                        hide-details="auto"
                        :items="subjects"
                        item-text="title"
                        item-value="id"
                        return-object
                        @change="getStudentsAssigned"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click:close="clearSubject"
                                small
                            >
                                <!-- {{ data.item.title }} -->
                                {{ formattedSubjectTitle }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col cols="2">
                    <v-select
                        v-model="selectedFormLevel"
                        outlined
                        dense
                        label="Form"
                        placeholder="Form"
                        chips
                        small-chips
                        deletable-chips
                        hide-details="auto"
                        :items="formLevels"
                        @change="setStudentsList"
                    ></v-select>
                </v-col>

                <v-col cols="4">
                    <v-select
                        v-model="selectedFormClass"
                        outlined
                        dense
                        label="Class"
                        placeholder="Select Class"
                        chips
                        small-chips
                        deletable-chips
                        hide-details="auto"
                        :items="formClassOptions"
                        item-text="id"
                        item-value="id"
                        @change="setStudentsList"
                    ></v-select>
                </v-col>

                <v-col cols="2">
                    <v-select
                        v-model="displayStudents"
                        outlined
                        dense
                        label="Display"
                        placeholder=" "
                        hide-details="auto"
                        :items="displayOptions"
                        @change="filterDisplay"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        v-model="selectedTeacher"
                        outlined
                        dense
                        label="Teacher"
                        placeholder="Select Teacher"
                        hide-details="auto"
                        chips
                        small-chips
                        deletable-chips
                        :items="employees"
                        item-text="name"
                        item-value="id"
                    ></v-autocomplete>
                </v-col>

                <v-col cols="2">
                    <v-text-field
                        v-model="studentsAssignedCount"
                        disaabled
                        outlined
                        dense
                        label="Assigned"
                        placeholder=" "
                        hide-details="auto"
                    ></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-text-field
                        v-model="search"
                        label="Search Student"
                        append-icon="mdi-magnify"
                        outlined
                        dense
                        clearable
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="studentsList"
                fixed-header                        
                height="48vh"         
                :search="search"
                :loading="loading"
                show-select
                item-key="student_id"
                dense  
                class="mt-5"              
            >
                <template v-slot:[`item.assigned`]="{ item }">
                    <v-icon
                        v-if="item.assigned"
                        color="primary"
                    >
                        mdi-checkbox-marked-outline
                    </v-icon>
                </template>            
            </v-data-table>

        </v-container>

        <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
                depressed
                @click="assignStudents"
                class="mr-6"
            >
                Download
            </v-btn> -->
            <v-btn
                depressed
                @click="unassignStudents"
                class="mr-4"
            >
                unassign Students
            </v-btn>
            <v-btn
                color="primary"
                @click="assignStudents"
            >
                Assign Students
            </v-btn>
        </v-card-actions>
        
        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
            :centered="snackbar.centered"            
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"                   
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="overlay">
            <v-progress-circular 
                indeterminate 
                size="64"
            ></v-progress-circular>
        </v-overlay>
        
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },

    computed: {
        formattedSubjectTitle () {
            if(this.selectedSubject && this.selectedSubject.title.length > 25){
                return this.selectedSubject.abbr;
            }
            return this.selectedSubject.title;
        },
        
        formClassOptions () {            
            if(this.selectedFormLevel){               
                return this.formClasses.filter(value => {
                    if(value.form_level == this.selectedFormLevel ) 
                    return value;
                })
            } 
            return this.formClasses;           
        },

        studentsAssignedCount () {
            if(this.subjectStudentsAssigned.length == 0) return '-';
            return this.subjectStudentsAssigned.length;
        },
    },

    data: () => ({
        selectedSubject: null,
        subjects: [],
        selectedFormLevel: null,
        formLevels: [],
        selectedFormClass: null,
        formClasses: [],
        displayStudents: null,
        displayOptions: [ "All", "Assigned", "Unassigned"], 
        selectedTeacher: null,
        employees: [],
        search: '',
        selected: [],
        headers: [
            {text: 'ID', align: 'start', value: 'student_id', width: '100'},
            {text: 'Name',  value: 'name', width: '200'},            
            {text: 'Class', value: 'form_class_id', width: '100'},            
            {text: 'Teacher',  value: 'teacher', width: '150' },
            {text: 'Assigned',  value: 'assigned', align: 'center' },
        ],
        students: [],  
        studentsList: [],
        loading: false,
        studentsAssigned: [],
        subjectStudentsAssigned: [],
        overlay: false,
        snackbar: {
            display: false,
            text: '',
            centered: false,
            color: 'primary',
        },
    }),

    methods: {
        ...mapActions({
            getSubjects: 'subjectAssignments/getSubjects',
            getFormClasses: 'subjectAssignments/getFormClasses',
            getTeachers: 'subjectAssignments/getTeachers',
            getStudents: 'subjectAssignments/getStudents',
            getSubjectStudentsAssigned: 'subjectAssignments/getStudentsAssigned',
            postSubjectStudentAssignments: 'subjectAssignments/postSubjectStudentAssignments',
            deleteSubjectStudentAssignments: 'subjectAssignments/deleteSubjectStudentAssignment',
        }),

        ...mapMutations({
            setSubjectSelected: 'subjectAssignments/setSubjectSelected',
            setSubjectStudentAssignments: 'subjectAssignments/setSubjectStudentAssignments',        
        }),

        async initialize(){
            try {
                const [
                    { data: dataSubjects },
                    { data: dataFormClasses },
                    { data: dataTeachers },
                    { data: dataStudents }
                ] = await Promise.all([
                    this.getSubjects(),
                    this.getFormClasses(),
                    this.getTeachers(),
                    this.getStudents()
                ])
                this.subjects = dataSubjects;
                this.mapFormLevels(dataFormClasses);
                this.formClasses = dataFormClasses;
                this.mapEmployees(dataTeachers);
                this.mapStudents(dataStudents);
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
        }, 
        
        mapStudents (data) {
            data.forEach(student => {
                student.name = student.last_name + ', ' + student.first_name;
            })
            this.students = data;
        },
        
        clearSubject () {
            this.selectedSubject = null;
            this.initialize();
        },

        mapFormLevels (data){
            data.forEach(formClass => {
                if(this.formLevels.indexOf(formClass.form_level) == -1) this.formLevels.push(formClass.form_level);
            })
        },

        mapEmployees (data){
            data.forEach(employee => {
                employee.name = employee.last_name + ', ' + employee.first_name;
            })
            this.employees = data;
        },

        async getStudentsAssigned () {
            if(!this.selectedSubject) return

            this.setSubjectSelected(this.selectedSubject.id);

            try {
                const { data } = await this.getSubjectStudentsAssigned();
                this.studentsAssigned = data;
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }

            this.setStudentsList();
        },

        setStudentsList () { 
            this.studentsList = []; 

            if(!this.selectedSubject) return;

            if(!this.selectedFormLevel && !this.selectedFormClass){
                console.log("subject only selected")
                this.studentsList = [];
                return
            } 

            if(this.selectedFormLevel && !this.selectedFormClass)
            {
                console.log("only form level selected");
                this.students.forEach(value => {
                    if(value.form_class_id && value.form_class_id[0] == this.selectedFormLevel){
                        this.studentsList.push({...value});
                    }
                })

                this.setStudentsAssigned();
                return;
            }

            if(this.selectedFormClass)
            {                
                console.log("subject and form class selected")
                this.students.forEach(value => {
                    if(value.form_class_id == this.selectedFormClass)
                    this.studentsList.push({...value});
                })
                this.setStudentsAssigned();
            }
                     
        },

        setStudentsAssigned () {
            let student = null, teacher = null;
            this.selected = [];
            this.subjectStudentsAssigned = [];
            this.studentsList = this.studentsList.map(value => {
                student = this.studentsAssigned.filter(item => {
                    if(item.student_id == value.student_id) return item;
                })
                value.assigned = false;
                value.teacher = null;
                if(student.length > 0){
                    teacher = null;
                    if(student[0].employee_id){
                        // teacher = this.teachers.filter(teacher => {
                        //     if(teacher.id == student[0].employee_id)
                        //     return teacher;
                        // })[0].teacher_name;
                        teacher = student[0].teacher_name;
                        
                    }
                    value.teacher = teacher;
                    value.assigned = true;
                    this.subjectStudentsAssigned.push(value);
                    // this.selected.push(value);
                }
                return value;
            })
        },

        filterDisplay () {            
            let studentAssigned = [];
            switch (this.displayStudents){
                case "All":
                    this.setStudentsList();
                    break;
                case "Assigned":
                    this.setStudentsList();                    
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.student_id == value.student_id) return item;
                        })
                        if(studentAssigned.length > 0){
                            return value;
                        }
                    })
                    break;
                case "Unassigned":
                    this.setStudentsList();
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.student_id == value.student_id) return item;
                        })
                        if(studentAssigned.length == 0){
                            return value;
                        }
                    })
                    break;        
           }
        },

        async assignStudents () {
            if(this.selected.length === 0){
                this.snackbar.color = "red";
                this.snackbar.text = "No students selected for assignment";
                this.snackbar.display = true;
                this.snackbar.centered = true;
                return;
            }

            this.setSubjectStudentAssignments({
                form_level: this.selectedFormLevel,
                form_class_id: this.selectedFormClass,
                students: this.selected,
                employee_id: this.selectedTeacher,
                subject_id: this.selectedSubject.id
            })

            await this.postAssignment();

        },

        async postAssignment () {
            this.overlay = true;
            try {
                await this.postSubjectStudentAssignments();
                this.displayStudents = "All";
                this.getStudentsAssigned();
                // this.setStudentsList();
                this.$nextTick(() => {
                    let msg = `${ this.selected.length} Student Assigned`;
                    if(this.selected.length > 1)
                    msg = `${ this.selected.length} Students Assigned`;
                    this.snackbar.text = msg;
                    this.snackbar.color = "primary";
                    this.snackbar.centered = false; 
                })
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.snackbar.text = "An error has occured.";
                this.snackbar.color = "red";
                this.snackbar.centered = false; 
            }
            this.overlay = false;
            this.snackbar.display = true;
        },

        async unassignStudents () {
            if(this.selected.length === 0){
                this.snackbar.color = "red";
                this.snackbar.text = "No students selected for unassignment";
                this.snackbar.display = true;
                this.snackbar.centered = true;
                return;
            }

            this.$emit('show-overlay', true)

            this.setSubjectStudentAssignments({
                students: this.selected,
                employee_id: this.selectedTeacher,
                subject_id: this.selectedSubject.id
            })

            try {
                const { data } = await this.deleteSubjectStudentAssignments();
                this.displayStudents = "All";
                this.getStudentsAssigned();
                this.$nextTick(() => {
                    let msg = `${ data } Student unassigned`;
                    if(data  > 1)
                    msg = `${ data } Students unassigned`;
                    this.snackbar.text = msg;
                    this.snackbar.color = "primary";
                    this.snackbar.centered = false;
                    this.snackbar.display = true;
                })
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.snackbar.text = "An error has occured. Could not unassign students";
                this.snackbar.color = "red";
                this.snackbar.centered = false;
                this.snackbar.display = true; 
            }

            this.$emit('show-overlay', false);
        },
        
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        font-size: 12px;
    }

    ::v-deep table th:first-child{
        width: 40px !important;
    }

    ::v-deep th span{
        display: inline;
    }
</style>