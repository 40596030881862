import axios from 'axios'

export default{
    namespaced: true,
    state: {
       academicYearId: null,
       formClassId: null, 
    },
    getters: {
       getAcademicYearId (state) {
        return state.academicYearId
       },

       getFormClassId (state) {
        return state.formClassId
       }
    },
    mutations: {
        setAcademicYearId (state, value) {
            state.academicYearId = value
        },

        setFormClassId (state, value) {
            state.formClassId = value
        }
    },
    actions: {

        getMarkSheetTerms () {
            let url = '/api/mark-sheet-terms';
            return axios.get(url);
        },
        
        getReportCardTerms () {
            let url = '/api/report-card-terms';
            return axios.get(url);
        },

        getClassListYears () {
            const url = '/api/class-list-years';
            return axios.get(url);
        },

        downloadASR () {
            const url = '/api/asr';
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer'
            })
        },

        downloadSpreadsheet ({ getters }) {
            const url = '/api/class-list-download';
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
                params: {
                    'form_class_id': getters.getFormClassId,
                    'academic_year_id': getters.getAcademicYearId
                }
            })
        },

        getFormClassesList({ getters }) {
            const url = '/api/form-classes-list'            
            return axios.get(url, {
                params: {
                    'academic_year_id': getters.getAcademicYearId
                }
            });
        },
    }
}    