import axios from 'axios';

class UploadFileService {
    upload(file, id, file_type, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);       
        formData.append('id', id);
        formData.append('file_type', file_type);

        return axios.post("/api/student-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    delete(id, file_name, file_type) {
        return axios.delete("/api/student-file", {
            params:{
                student_id: id,
                file_type: file_type,
                file_name: file_name
            }
        });
    }

    uploadSpreadsheet(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post("/api/store-registration-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    getPicture($student_id) {
        return axios.get("/api/student-picture/"+$student_id)
    }
}

export default new UploadFileService();