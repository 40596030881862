<template>    
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 pb-3 mx-auto"
                width="1000"
                
            >
                <v-card-title
                    class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                >
                    <v-progress-circular
                        indeterminate
                        v-show="saving"
                        size="16"
                        width="3"
                        class="ml-3"
                        color="secondary"
                    ></v-progress-circular>                
                
                    <v-icon
                        small
                        color="secondary"
                        v-show="saved"
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>

                    <v-tooltip 
                        top               
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                color="secondary"
                                v-show="savedToDb"
                                class="ml-3"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-cloud-check-outline
                            </v-icon>                                
                        </template>
                        <span>Record Saved</span>
                    </v-tooltip>

                    <v-icon
                        small
                        color="red"                   
                        v-show="saveError"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>

                    <span
                        class="secondary--text ml-4"
                    >{{ saveStatus }}</span>

                    <v-spacer></v-spacer>
                    <span>Edit / View Term Reports</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="close"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn> 
                </v-card-title>
                <v-card-text
                class="pb-0"
                >
                    <v-row>
                        <student-details
                             @close="close"
                        ></student-details>
                        <subject-records></subject-records>
                    </v-row>
                </v-card-text>
                
                <v-overlay
                    absolute
                    :value="overlay"
                    color="grey"
                    opacity="0.6"
                >
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="primary"
                    ></v-progress-circular>
                    <v-expand-transition>                            
                        <v-card
                            width="250"
                            class="mx-auto"
                            transition="scroll-y-transition"
                            v-show="expand"
                        >
                            <v-toolbar
                                color="primary"
                                dark
                            >
                                <v-toolbar-title>Select Class</v-toolbar-title>
                            </v-toolbar>
                                <div style="max-height: 400px; width: 250px; overflow-y: auto">    
                                <v-list light >
                                    <v-list-group
                                        v-for="item in formClasses"
                                        :key = "item.title"
                                        v-model="item.active"
                                        :prepend-icon="item.action"
                                        no-action
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item
                                            v-for="subItem in item.items"
                                            :key="subItem.title"
                                            @click="setSelectedClass(subItem.title)"                                       
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    {{ subItem.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                            </div>
                        </v-card>
                    </v-expand-transition>
                </v-overlay>
                                    
            </v-card>
        </v-col>
        <v-bottom-sheet 
            v-model="sheet" 
            inset
            persistent
        >
            
            <v-sheet class="text-center px-10" height="200px">
                <v-row justify="end">
                    <div>
                        <v-btn
                            class="mt-1"
                            text
                            color="blue darken-3"
                            @click="closeBottomSheet"
                        >close</v-btn>
                    </div>
                </v-row>                
                <v-form ref="form">
                    <v-textarea                                       
                        :label="sheetTitle"                        
                        hint="Max 215 Characters"
                        rows="3"                                           
                        counter                                             
                        no-resize
                        v-model="studentRecord[sheetModel]"
                        autofocus                        
                        persistent-hint
                        maxlength="215"                                                                                                                 
                    >                        
                    </v-textarea>
                </v-form>                    
            </v-sheet>
        </v-bottom-sheet>           
    </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import StudentDetails from './EditViewTermReportsStudentDetails';
import SubjectRecords from './EditViewTermReportsSubjectRecords';
export default {
    components: {
        StudentDetails,
        SubjectRecords,
    },
    created(){
        this.initialize();        
    },
    data: () => ({       
       error: false,
       errorMessage: '',       
       rules: [
            value => {
                if( value.length <= 215){
                    
                    return true;
                }
                else{
                    return 'Max 215 characters';
                }
            }
        ],
        formClasses: [],
        comments: '',            
    }),
    computed: {
        ...mapGetters({            
            overlay: 'termReports/getOverlay',
            loading: 'termReports/getLoading',
            expand: 'termReports/getExpand',
            academicTermId: 'app/getAcademicTermId',
            academicYearId: 'app/getAcademicYearId',
            getUser: 'auth/getUser',
            sheet: 'termReports/getSheet',
            sheetTitle: 'termReports/getSheetTitle',
            sheetModel: 'termReports/getSheetModel',
            studentRecord: 'termReports/getStudentRecord',
            saveStatus: 'termReports/getSaveStatus',
            saving: 'termReports/getSaving',
            saveError: 'termReports/getError',
            saved: 'termReports/getSaved',
            savedToDb: 'termReports/getSavedToDb',
            updating: 'termReports/getUpdating',            
        }),
        
        // user () {
        //     return JSON.parse(this.getUser);
        // }
    },

    watch: {
        updating: {
            handler (value) {
                console.log(`Updating: ${value}`);
                if(!value){ 
                    setTimeout(() => {
                        this.setSaved(false);
                        this.setSaveStatus('');
                        this.setSavedToDb(true); 
                    }, 1500);  
                }
            }
        }
    },
    
    methods: {
        initialize(){
            this.setExpand(false);
            this.setOverlay(true);
            this.setLoading(true);            
            this.setFormClasses();
        },
        ...mapMutations({
            setOverlay: 'termReports/setOverlay',
            setLoading: 'termReports/setLoading',
            setExpand: 'termReports/setExpand',
            setSelectedFormClass: 'termReports/setSelectedClass',
            setAcademicTermId: 'termReports/setAcademicTermId',
            setAcademicYearId: 'termReports/setAcademicYearId',
            setEmployeeId: 'termReports/setEmployeeId',
            setStudentRecord: 'termReports/setStudentRecord',
            setStudentsRecords: 'termReports/setStudentsRecords',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setPagination: 'termReports/setPagination',
            setFormClassAssignment: 'termReports/setFormClassAssignment',
            setFormDeanAssignments: 'termReports/setFormDeanAssignments',
            setCurrentPage: 'termReports/setCurrentPage',
            setSheet: 'termReports/setSheet',
            setSaveStatus: 'termReports/setSaveStatus',            
            setSaved: 'termReports/setSaved',
            setSavedToDb: 'termReports/setSavedToDb',
            setSubjects: 'termReports/setSubjects',
            setCourseWorkOnly: 'termReports/setCourseWorkOnly',
            setEmployees: 'termReports/setEmployees',
        }),
        ...mapActions({
            getFormClassesList: 'termReports/getFormClassesList',
            getStudentTermDetails: 'termReports/getStudentTermDetails',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            getFormTeacherAssigment: 'termReports/getFormTeacherAssignment',
            getFormDeanAssignment: 'termReports/getFormDeanAssignments',
            getSubjects: 'timetable/getSubjects',
            getTermConfiguration: 'termReports/getTermConfiguration',
            getEmployees: 'employees/getEmployees', 
        }),
        async setFormClasses(){
            try {
                console.log('getting form classes...');
                const promiseEmployees = this.getEmployees();
                const promiseFormClasses = this.getFormClassesList();
                const [ { data }, { data: employees}] =
                await Promise.all([promiseFormClasses, promiseEmployees]);
                this.mapFormClasses(data);
                this.setEmployees(employees);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        mapFormClasses (data) {
            if(data.length > 0){                    
                    let form1Classes = [];
                    let form2Classes = [];
                    let form3Classes = [];
                    let form4Classes = [];
                    let form5Classes = [];
                    let form6Classes = []; 
                    data.forEach(record => {
                        switch(record.form_level){
                            case "1":
                            form1Classes.push({
                                title: record.id
                            });
                            break;
                            case "2":
                            form2Classes.push({
                                title: record.id
                            });
                            break;
                            case "3":
                            form3Classes.push({
                                title: record.id
                            });
                            break;
                            case "4":
                            form4Classes.push({
                                title: record.id
                            });
                            break;
                            case "5":
                            form5Classes.push({
                                title: record.id
                            });
                            break;
                            case "6":
                            form6Classes.push({
                                title: record.id
                            });
                            break;
                        }                
                    });
                    this.formClasses.push({
                        title: 'Form 1',
                        action: 'mdi-school',                
                        items: form1Classes
                    });
                    this.formClasses.push({
                        title: 'Form 2',
                        action: 'mdi-school',                
                        items: form2Classes
                    });
                    this.formClasses.push({
                        title: 'Form 3',
                        action: 'mdi-school',                
                        items: form3Classes
                    });
                    this.formClasses.push({
                        title: 'Form 4',
                        action: 'mdi-school',                
                        items: form4Classes
                    });
                    this.formClasses.push({
                        title: 'Form 5',
                        action: 'mdi-school',                
                        items: form5Classes
                    });
                    this.formClasses.push({
                        title: 'Form 6',
                        action: 'mdi-school',                
                        items: form6Classes
                    });
                    this.setLoading(false)
                    setTimeout(() => this.setExpand(true));                          
                }
        },

        async setSelectedClass(formClass){
            this.setExpand(false);
            setTimeout(() => this.setLoading(true), 1000);           
            this.setSelectedFormClass(formClass);            
            this.setAcademicTermId(this.academicTermId);
            this.setAcademicYearId(this.academicYearId);
            this.setEmployeeId(sessionStorage.getItem('employeeId'));
            try {
                const formTeacherAssignmentPromise = this.getFormTeacherAssigment();
                const studentTermDetailsPromise = this.getStudentTermDetails();
                const formDeanAssignmentPromise = this.getFormDeanAssignment();
                const getSubjectsPromise = this.getSubjects();
                const getTermConfigurationPromise = this.getTermConfiguration();
                
                const [
                    { data: dataStudentTermDetails },
                    { data: dataFormTeacherAssignment },
                    { data: dataFormDeanAssignment },
                    { data: dataSubjects },
                    { data: dataTermConfiguration },
                ] = await Promise.all([
                    studentTermDetailsPromise,
                    formTeacherAssignmentPromise,
                    formDeanAssignmentPromise,
                    getSubjectsPromise,
                    getTermConfigurationPromise,
                ])

                let formClassAssignments = [];
                if(dataFormTeacherAssignment.length > 0){
                    dataFormTeacherAssignment.forEach(record => {
                        formClassAssignments.push(record.form_class_id);
                    })
                    this.setFormClassAssignment(formClassAssignments);
                }

                this.setSubjects(dataSubjects);                
                
                let formDeanAssignments = [];
                if(dataFormDeanAssignment.length > 0){
                    dataFormDeanAssignment.forEach(record => {
                        formDeanAssignments.push(record.form_class_id);
                    })
                    this.setFormDeanAssignments(formDeanAssignments);
                }                

                this.setStudentRecord(dataStudentTermDetails[0]);
                this.setStudentsRecords(dataStudentTermDetails);
                this.makePagination(dataStudentTermDetails);
                

                this.setCourseWorkOnly(true);                
                if(dataTermConfiguration && dataTermConfiguration.exam_mark == 1) this.setCourseWorkOnly(false);                

                const { data: dataStudentTermMarks } = await this.getStudentTermMarks();
                // console.log(dataStudentTermMarks)
                this.setStudentTermMarks(dataStudentTermMarks);
                this.setSavedToDb(true);               
                
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setLoading(false);
            setTimeout(() => this.setOverlay(false));            
        },
        async setStudentRecordDisplay(){
            try {
                let response = await this.getStudentTermDetails();
                //console.log(response);
                this.makePagination(response.data);
                if(response.data.data.length > 0){                    
                    this.setStudentRecord(response.data.data[0]);
                    //console.log(response.data.data[0])                    
                }
                
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);
                //console.log(response);
                
                this.setLoading(false);
                setTimeout(() => this.setOverlay(false));
            } catch (error) {
                console.log(error);
            }
        },
         makePagination(data){
            let pagination = {
                current_page: 1,
                last_page: data.length,
                next_page: 2,
                prev_page: null,
            }
            this.setPagination(pagination);
        },
        close(){
            this.setOverlay(true);
            this.setExpand(true);
        },
        closeBottomSheet(){
            this.setSheet(false);            
                     
        }
    }

}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 15px;
    }
</style>